import { MDCRipple } from '@material/ripple/index'
import { MDCSelect } from '@material/select'
import { MDCFormField } from '@material/form-field'
import { MDCCheckbox } from '@material/checkbox'
import { MDCMenu } from '@material/menu'
import { MDCDataTable } from '@material/data-table'
import { MDCRadio } from '@material/radio'
import { MDCSwitch } from '@material/switch'
import { MDCTextField } from '@material/textfield'
import { MDCTabBar } from '@material/tab-bar'
import { MDCChipSet } from '@material/chips'
import { MDCIconButtonToggle } from '@material/icon-button'
import { tabBar } from 'material-components-web'
import { MDCTopAppBar } from '@material/top-app-bar'
import { MDCList } from '@material/list'
import { MDCDrawer } from '@material/drawer'
import { getCorrectEventName } from '@material/animation'
import autoComplete from './autocomplete'

if (process.env.NODE_ENV !== 'production') {
    console.log('Looks like we are in development mode!')
}

function fcaInit_() {
    // init fuction ----------------------

    //DROWERS
    if (document.getElementsByClassName('mdc-drawer').length > 0) {
        const drawer = MDCDrawer.attachTo(document.querySelector('.mdc-drawer'))
        const topAppBar = MDCTopAppBar.attachTo(
            document.getElementById('app-bar')
        )
        // topAppBar.setScrollTarget(document.getElementById("main-content"));
        topAppBar.listen('MDCTopAppBar:nav', () => {
            drawer.open = !drawer.open
        })
    }

    // CHIPS
    if (document.getElementsByClassName('mdc-chip-set').length > 0) {
        const chipSetEl = document.querySelector('.mdc-chip-set')
        const chipSet = new MDCChipSet(chipSetEl)
    }

    // TABLE
    if (document.getElementsByClassName('mdc-data-table').length > 0) {
        const dataTable = new MDCDataTable(
            document.querySelector('.mdc-data-table')
        )
    }

    // SELECT
    if (document.getElementsByClassName('mdc-select__menu').length > 0) {
        const menuSelect = new MDCMenu(
            document.querySelector('.mdc-select__menu')
        )
        menuSelect.open = false
    }

    // BUTTON
    if (document.getElementsByClassName('fca-button').length > 0) {
        const ripple = new MDCRipple(document.querySelector('.fca-button'))
    }

    // SELECT
    if (document.getElementsByClassName('mdc-select').length > 0) {
        const selects = [].map.call(
            document.querySelectorAll('.mdc-select'),
            function (el) {
                return new MDCSelect(el)
            }
        )
    }

    // CARD
    if (
        document.getElementsByClassName('mdc-button').length > 0 ||
        document.getElementsByClassName('mdc-icon-button').length > 0 ||
        document.getElementsByClassName('mdc-card__primary-action').length > 0
    ) {
        const selector =
            '.mdc-button, .mdc-icon-button, .mdc-card__primary-action'
        const ripples = [].map.call(
            document.querySelectorAll(selector),
            function (el) {
                return new MDCRipple(el)
            }
        )
    }

    // FORM FIELD
    if (
        document.getElementsByClassName('mdc-form-field').length > 0 &&
        document.getElementsByClassName('mdc-checkbox').length > 0
    ) {
        const formField = new MDCFormField(
            document.querySelector('.mdc-form-field')
        )
        const checkbox = new MDCCheckbox(
            document.querySelector('.mdc-checkbox')
        )
        formField.input = checkbox
    }

    //RADIO
    if (
        document.getElementsByClassName('mdc-radio').length > 0 &&
        document.getElementsByClassName('mdc-form-field').length > 0
    ) {
        const formField = new MDCFormField(
            document.querySelector('.mdc-form-field')
        )
        const radios = [].map.call(
            document.querySelectorAll('.mdc-radio'),
            function (el) {
                // return new MDCRadio(el);
                formField.input = new MDCRadio(el)
            }
        )
    }

    //SWITCH
    if (document.getElementsByClassName('mdc-switch').length > 0) {
        const switchControl = [].map.call(
            document.querySelectorAll('.mdc-switch'),
            function (el) {
                return new MDCSwitch(el)
            }
        )
    }

    //TEXT FIELD
    if (document.getElementsByClassName('mdc-text-field').length > 0) {
        const textFields = [].map.call(
            document.querySelectorAll('.mdc-text-field'),
            function (el) {
                return new MDCTextField(el)
            }
        )
    }

    //CONTAINER MENU
    if (document.getElementsByClassName('container-menu').length > 0) {
        ;[].map.call(
            document.querySelectorAll('.container-menu'),
            function (el) {
                let menu = new MDCMenu(el.querySelector('.mdc-menu'))
                menu.open = false
                el.querySelector('.fca-button-menu').addEventListener(
                    'click',
                    function (e) {
                        let menu = new MDCMenu(
                            e.srcElement.parentElement.querySelector(
                                '.mdc-menu'
                            )
                        )
                        menu.open = !menu.open
                    }
                )
            }
        )
    }

    // TAB BAR
    if (document.getElementsByClassName('mdc-tab-bar').length > 0) {
        ;[].map.call(document.querySelectorAll('.mdc-tab-bar'), function (el) {
            let tabBar = new MDCTabBar(el)
            tabBar.listen('MDCTabBar:activated', function (event) {
                // Hide currently-active content
                event.srcElement.parentElement
                    .querySelector('.content--active')
                    .classList.remove('content--active')
                // Show content for newly-activated tab
                event.srcElement.parentElement
                    .querySelectorAll('.content')
                    [event.detail.index].classList.add('content--active')
            })
        })
    }

    // ICON BUTTON
    if (document.getElementsByClassName('mdc-icon-button').length > 0) {
        const iconButtonRipple = [].map.call(
            document.querySelectorAll('.mdc-icon-button'),
            function (el) {
                return new MDCRipple(el)
            }
        )
        iconButtonRipple.unbounded = true

        const iconToggle = [].map.call(
            document.querySelectorAll('.mdc-icon-button'),
            function (el) {
                return new MDCIconButtonToggle(el)
            }
        )
    }

    // TOOLTIP
    if (document.getElementsByClassName('fca-tooltip').length > 0) {
        ;(function (document, window, undefined) {
            'use strict'

            // Find each tooltip
            var tooltip = document.querySelectorAll('.fca-tooltip')

            ;[].forEach.call(tooltip, function (el) {
                // Create tooltip element
                var tooltipText = document.createElement('div')

                // Set tooltip text
                tooltipText.textContent = el.getAttribute('data-tooltip-text')
                tooltipText.classList.add('fca-tooltip-text')

                // Add tooltip to body on mouse over
                el.addEventListener(
                    'mouseover',
                    function () {
                        document.body.appendChild(tooltipText)
                    },
                    false
                )

                // Remove tooltip on mouseout
                el.addEventListener(
                    'mouseout',
                    function () {
                        document.body.removeChild(tooltipText)
                    },
                    false
                )

                // Attach the tooltip to the mouse cursor
                el.addEventListener(
                    'mousemove',
                    function (e) {
                        tooltipText.style.top = e.pageY + 20 + 'px'
                        tooltipText.style.left = e.pageX + 20 + 'px'
                    },
                    false
                )
            })
        })(document, window)
    }

    // // AIMATION
    const eventToListenFor = getCorrectEventName(window, 'animationstart')

    // // ACCORDION ARROW
    if (document.getElementsByClassName('fca-accordion').length > 0) {
        const accordions = document.querySelectorAll('.fca-accordion')
        for (const accordion of accordions) {
            const panels = accordion.querySelectorAll('.fca-accordion-panel')
            for (const panel of panels) {
                const head = panel.querySelector('.fca-container-label')
                head.onclick = () => {
                    for (const otherPanel of panels) {
                        if (otherPanel !== panel) {
                            otherPanel.classList.remove(
                                'fca-accordion-expanded'
                            )
                        }
                    }
                    const icon = head.querySelector('.change-arrow')
                    if (icon.innerHTML.toString().indexOf('up') > -1) {
                        icon.innerHTML = ''
                        icon.innerHTML = 'keyboard_arrow_down'
                    } else if (icon.innerHTML.toString().indexOf('down') > -1) {
                        icon.innerHTML = ''
                        icon.innerHTML = 'keyboard_arrow_up'
                    }
                    panel.classList.toggle('fca-accordion-expanded')
                }
            }
        }
    }

    //Date picker
    if (document.getElementsByClassName('datePicker').length > 0) {
        ;[].map.call(document.querySelectorAll('.datePicker'), function (el) {
            return datepicker(el, {
                onSelect: (instance, date) => {
                    el.setAttribute('dateSelect', date)
                    console.log(date)
                }
            })
        })
    }

    if (window.fcaGuideLinesDebug) console.log('fcaguidelines execution end')
    // End init fuction ----------------------
}

window.fcaInit = fcaInit_

fcaInit()

// -------------------------------------------------------------------------------
class AutoComplete extends HTMLElement {
    constructor() {
        super()
        this.value = ''
        this.selector = 'autocomplete'
        this.createComponent = this.createComponent.bind(this)
        this.onChangeHandler= this.onChangeHandler.bind(this)
    }

    static get observedAttributes() {
        return ['value', 'list']
    }

    getData(value) {
        let newValue = value

        if (value) {
            newValue = value.replace(/\(\)/, '')
            if (typeof window[newValue] === 'function') {
                newValue = window[newValue]()
            } else {
                newValue = newValue.replaceAll("'", "\'")
                newValue = JSON.parse(newValue)
            }
        }
        return newValue
    }

    createComponent() {
        const name = this.getAttribute('name') || 'autoComplete'
        const label = this.getAttribute('label') || ''
        this.value = this.getAttribute('value') || this.value
        const searchMode = this.getAttribute('searchMode')
        const list = this.getData(this.getAttribute('list'))
        const key = this.getAttribute('keySearch')
        const placeHolder = this.getAttribute('placeholder') || 'Cerca...'
        this.selector = this.getAttribute('selector')
        const minSearch = this.getAttribute('minLenght') || 1
        const debounce = this.getAttribute('debounce') || 125
        const maxResult = this.getAttribute('maxResult') || 5
        const selectedFunction = this.getAttribute('selectionCallback')
        const resultItem = this.getAttribute('resultItem')
        const noResultsLabel =
            this.getAttribute('noResultsItems') || 'No result found for '
        const icon = this.getAttribute('icon') || ''

        
        
        this.innerHTML = `<div class="autocomplete-wrapper">
        <label class="stl-input">
        <input type="text" id="${this.selector}" value="${this.value}" autocomplete="off">
        <span>${label}</span>
        </label>
        </div>`

        const autocomplete = new autoComplete({
            name: name,
            data: {
                src: list,
                key: [key]
            },
            trigger: {
                event: ['input', 'focus']
            },
            placeHolder: placeHolder,
            selector: `#${this.selector}`,
            threshold: minSearch,
            debounce: debounce,
            maxResults: maxResult,
            highlight: true,
            searchEngine: (val, element) => {
                let result
                this.value = val
                if (searchMode === 'strict') {
                    let re = new RegExp('(?:^|\\s)(' + val + 'w*)', 'i')
                    result = re.test(element) ? element : null
                } else if (searchMode === 'fullText') {
                    let arr1 = val.split(' ')

                    return arr1.filter((s) => {
                        return (
                            element.toLowerCase().indexOf(s.toLowerCase()) > -1
                        )
                    }).length == arr1.length
                        ? element
                        : null
                } else {
                    result =
                        element.toLowerCase().indexOf(val.toLowerCase()) > -1
                            ? element
                            : null
                }
                return result
            },
            resultItem: {
                content: (data, element) => {
                    let newElement = null

                    if (typeof window[resultItem] === 'function') {
                        newElement = window[resultItem](data)
                    }

                    let noFound = document.getElementById('no-found')
                    noFound ? noFound.remove() : null
                    const key = Object.keys(data).find(
                        (key) => data[key] === element.innerText
                    )

                    element.style =
                        'display: flex; justify-content: space-between;'
                    element.innerHTML =
                        newElement ||
                        `<span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
             ${element.innerHTML}</span>`
                }
            },
            onSelection: (data) => {
                this.setAttribute('value', data.selection.match)
                if (selectedFunction) {
                    let newSelectedFunction = selectedFunction.replace(
                        /\(\)/,
                        ''
                    )
                    if (typeof window[newSelectedFunction] === 'function') {
                        window[newSelectedFunction](
                            data.selection,
                            data.results
                        )
                    }
                }
            },
            noResults: (search, generateList) => {
                generateList(autocomplete, search, search.result)

                const result = document.createElement('div')
                result.setAttribute('class', 'no-found')
                result.innerHTML = `<span>${noResultsLabel} "${search.query}"</span>`
                return document
                    .querySelector(`#${autocomplete.resultsList.idName}`)
                    .appendChild(result)
            }
        })
    }

    connectedCallback() {
        this.createComponent()
    }

    attributeChangedCallback(name, oldValue, newValue) {
        this.createComponent()
        if (name === 'list' && oldValue !== null && oldValue !== newValue) {
            // let newValue= document.getElementById(this.selector).value || ''
            document.getElementById(this.selector).value = ' '
            document.getElementById(this.selector).focus()
            // document.getElementById(this.selector).value = newValue
            document.getElementById(this.selector).value = this.value

        }
    }
}

customElements.define('auto-complete', AutoComplete)
